/** @jsx jsx */
// import React from 'react'
import { Box, jsx, Image } from "theme-ui";
import { Link } from "gatsby";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import React, { useCallback } from "react";
import bhyasis from "../../static/imgs/bhyasis.png";
import logo from "../../static/imgs/Heartfulness_Logo_Centered_Unity_Type-1.png";
import LayoutHome from "../layout/LayoutHome";
import UsaBhyasisSlider from "../components/UsaBhyasisSlider";
import dtdLogo from "../../static/imgs/double-the-donation-logo.png";
import goldenOpportunityBg from "../../static/imgs/golden_opportunity_bg.jpg";
import { eventCallbackTriggers } from "../templates/wppage";

const pageContent =
  '<gatsby_donation amount="0" btntext="Contribute" donationid="21"' +
  'projectid="" btnbg="#4d8f15" colortext="black" colorprimary="forestgreen"' +
  'colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="Heartfulness USAbhyasis Donation"/>';
const PageContent = React.memo(PageContentNonMemoized);

const Usabhyasis = () => {
  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("usabhyasis-fund", event),
    []
  );

  return (
    <LayoutHome
      seoTitle="USAbhyasis - Donations"
      contentHeader={<Image alt="bhyasis" src={bhyasis} />}
    >
      <React.Fragment>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Image src={logo} alt="logo" />
        </Box>
        <Box sx={{ margin: "20px 0" }}>
          <h1 sx={{ textAlign: "center" }}>A Global Community of Hearts</h1>
          <p>
            In the last two years, the Heartfulness Meditation Movement has seen
            quantum growth across the globe. Today we have
          </p>
          <ul sx={{ fontSize: "18px", pb: 1 }}>
            <li>
              Over a{" "}
              <span sx={{ color: "#097bc3" }}>million new meditators</span>{" "}
              across 130 countries.
            </li>
            <li>Thousands of certified volunteer instructors</li>
            <li>
              Over 5000 <span sx={{ color: "#097bc3" }}>HeartSpots</span> and
              over 300 retreat centers around the world where people gather to
              meditate.
            </li>
            <li>
              Ongoing meditation training at{" "}
              <span sx={{ color: "#097bc3" }}>2,500 schools and colleges.</span>
            </li>
            <li>
              100,000 professionals meditating in over{" "}
              <span sx={{ color: "#097bc3" }}>
                3,000 corporations, NGOs and government organizations
              </span>
            </li>
          </ul>
          <p>
            This pace of growth is expected to accelerate in the future to
            resonate with Daaji’s vision of making Heartfulness known and
            available to all households in the world. This calls for
            corresponding investments in facilities, infrastructure and other
            resources to support this unprecedented growth.
          </p>
        </Box>
        <Box
          sx={{
            margin: "60px -60px",
            pl: "120px",
            pr: "60px",
            py: 5,
            background: `url(${goldenOpportunityBg}) no-repeat center center`,
            backgroundSize: "cover",
          }}
        >
          <h1 sx={{ textAlign: "center", fontWeight: "bolder" }}>
            <b sx={{ color: "#097bc3" }}>A Golden</b> Opportunity
          </h1>
          <p>
            This is a golden opportunity to build a strong financial foundation
            for Heartfulness programs for the benefit of one and all.
          </p>
          <p>
            Volunteers have consistently, generously and wholeheartedly donated
            their time and resources to support Heartfulness activities. This
            donation request is another opportunity for us to support the
            programs and facilities that will continue to serve people of all
            walks of life, cultures and backgrounds.
          </p>
          <p>
            Please only donate willingly and within the limits of your financial
            means. Every amount helps, no matter how small. Funds will be put to
            good use, as and when they are needed .
          </p>
          <p>
            Please note that online donations are subject to a 2% processing fee
            from the credit card company. So donors of large amounts are
            encouraged to mail a check along with this{" "}
            <span sx={{ color: "#097bc3" }}>
              <a
                rel="noreferrer"
                target="_blank"
                href="/SRCM_General_Donation_Form_3.pdf"
              >
                form
              </a>
            </span>
            . All donations will be tax deductible.
          </p>
          <div style={{ height: "500px" }}>
            <UsaBhyasisSlider />
          </div>
        </Box>
        <PageContent eventCallback={eventCallback} pageContent={pageContent} />
        <p>
          <Link sx={{ color: "#1da1f2" }} to="/shri-ram-chandra-mission-usa">
            Other means of donations are also available for transferring stock,
            in-kind, bank transfer etc.
          </Link>
        </p>
        <p>
          <Link sx={{ color: "#1da1f2" }} to="/corporate-gift-matching">
            List of companies where our organizations are already approved for
            Corporate Gift Matching.
          </Link>
        </p>
        <p sx={{ pb: "20px" }}>
          Please contact{" "}
          <a href="mailto:US.donations@heartfulness.org">
            US.donations@heartfulness.org
          </a>{" "}
          for any questions.
        </p>
        <Box
          sx={{
            boxShadow: "0 2px 4px 0 rgb(170 170 170 / 50%)",
            border: "solid 1px rgba(194,198,206,0.5)",
            padding: "30px",
            my: 5,
            textAlign: "center",
          }}
        >
          <p
            sx={{
              p: 5,
              fontSize: "20px",
              fontWeight: "bold",
              lineHeight: "40px",
            }}
          >
            There is an issue preventing{" "}
            <a sx={{ color: "#1da1f2" }} href="https://doublethedonation.com/">
              {" "}
              Double the Donation`s tool
            </a>{" "}
            from displaying the matching gift forms, guidelines, and
            instructions that your donors need to submit their match requests.{" "}
            <a
              sx={{ color: "#1da1f2" }}
              href="https://support.doublethedonation.com/portal/en/kb/articles/plugin-error-messaging-issue-preventing-tool-from-displaying-matching-gift-forms"
            >
              Learn more about this issue
            </a>{" "}
            and then contact{" "}
            <a
              sx={{ color: "#1da1f2" }}
              href="https://doublethedonation.com/contact-us/"
            >
              {" "}
              Double the Donation`s support team
            </a>{" "}
            to resolve this issue.
          </p>
          <small>All information provided by</small>
          <br />
          <a
            sx={{ color: "#1da1f2", fontWeight: "bold" }}
            href="https://doublethedonation.com/matching-grant-resources/matching-gift-information/"
          >
            Double the Donation
          </a>
          <br />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://doublethedonation.com/"
          >
            <img src={dtdLogo} alt="logo" width="26px" height="26px" />
          </a>
        </Box>
      </React.Fragment>
    </LayoutHome>
  );
};

export default Usabhyasis;
